<script setup lang="ts">
import { VerticalProps } from "./Vertical.props"
import { formatMoney, formattedPricePerUnit } from "~/utils/formatters"
import { PRODUCT_CLICKED } from "~/config/algolia-insights"
import {
  algoliaEventClickProductAfterSearch,
  algoliaEventClickProduct
} from "~/utils/algolia"
import { algoliaSearch } from "~/config/algolia"
import { fallbackImage, addToCartPosition } from "~/utils/constants"
import type { GA4Entities } from "nuxt-ga4"
import { ProductCardButtonsProps } from "~/components/Utils/ProductCardButtons.props"
import { isGreaterThan } from "~/utils/productPrice"
import { productUtils } from "~/utils/product"
import { SaveForLaterProductForAction } from "~/composables/useSavedForLater"
import { getSkuItemMetadata } from "~/utils/savedForLater/savedForLater"

const props = withDefaults(defineProps<VerticalProps>(), {
  oldPrice: 0,
  pricePerUnit: 0,
  reviews: 0,
  rating: 0,
  availableIn: 0,
  inStock: false,
  isInSavedForLater: false,
  sellabilityStatus: "sellable",
  lastItems: false,
  showQuantity: false,
  userLogged: false,
  unitType: "",
  showSaveForLater: true
})

const { addToCart } = useCart()
const { closeModal } = useModal()
const skuState = useState("product-unavailable-state", () => "")

const emit =
  defineEmits<{
    (e: "notification"): void
  }>()

const isAddingToCart = ref(false)
const disableLink = ref(false)

const clickProductEvent = () => {
  const algolia = algoliaSearch()
  localStorage.setItem(
    "algolia-last-clicked-product",
    `{"queryID": "${props?.queryID ?? ""}","path": "${props?.path ?? ""}"}`
  )
  if (props.queryID) {
    algoliaEventClickProductAfterSearch(
      algolia.indices.products,
      `${PRODUCT_CLICKED}`,
      [`product_${props.productCode}`],
      props.queryID,
      [props.position ?? 1]
    )
  } else {
    algoliaEventClickProduct(algolia.indices.products, `${PRODUCT_CLICKED}`, [
      `product_${props.productCode}`
    ])
  }

  if (props.isInPrecheckout) {
    closeModal("pre-checkout")
  }
}

const statefulEntityItem: GA4Entities["gaItem"] | undefined =
  inject("statefulEntityItem")

const handleAddToCart = (quantity: number) => {
  const { sendEventAddToCart } = useGA4SiteEvents()
  isAddingToCart.value = true

  disableLink.value = true
  useTimeoutFn(() => (disableLink.value = false), 2000)

  useTimeoutFn(() => {
    isAddingToCart.value = false
    disableLink.value = false
  }, 2000)
  emit("notification")

  addToCart(
    {
      sku_code: props.productCode,
      metadata: {
        product_image: props?.productImage!,
        slug: props.path,
        price: props.currentPrice,
        oldPrice: props.oldPrice,
        inStock: props.inStock,
        expirationDate: props?.expirationDate ?? "",
        unitType: props?.unitType,
        isDeductable: props?.isDeductable,
        ga4Item: statefulEntityItem?.value ?? {}
      }
    },
    quantity
  )

  if (Object.keys(statefulEntityItem?.value ?? {}).length) {
    sendEventAddToCart(statefulEntityItem?.value ?? {}, {
      currentPrice: props.currentPrice?.toString() ?? "",
      position: addToCartPosition.carousel
    })
  }
}

const handleNotifyMe = () => {
  disableLink.value = true
  useTimeoutFn(() => (disableLink.value = false), 2000)

  skuState.value = props?.productCode ?? ""

  const { event, sendEvent } = useGAEvent(
    `track_product_availability_warning_${props.productCode ?? ""}_`,
    "custom_event"
  )

  if (Object.keys(statefulEntityItem?.value ?? {}).length) {
    event.value = {
      event: "custom_event",
      action: "product_availability_warning",
      items: [statefulEntityItem?.value ?? {}]
    }

    sendEvent({ clearEcommerce: false })
  }
  emit("notification")
}

const product = computed((): SaveForLaterProductForAction => {
  return {
    productId: props?.productCode ?? "",
    skuId: "",
    metadata: getSkuItemMetadata(
      props?.productImage,
      props?.slug ?? "",
      props.currentPrice ?? 0,
      props.oldPrice,
      props.inStock ? 1 : 0,
      props.unitType,
      props.expirationDate,
      props.isDeductable
    )
  }
})
const productCardButton = computed((): ProductCardButtonsProps => {
  return {
    inStock: props.inStock,
    sellabilityStatus: props.sellabilityStatus,
    showQuantity: props.showQuantity,
    userLogged: props.userLogged,
    queryID: props.queryID,
    position: props.position,
    productCode: props.productCode,
    path: props.path,
    product: product.value,
    showSaveForLater: props.showSaveForLater
  }
})

const preventNavigation = () => {
  disableLink.value = true
  useTimeoutFn(() => (disableLink.value = false), 2000)
}
</script>

<template>
  <div
    class="
      product_tile-overall-vertical
      relative
      flex
      h-full
      w-[242px]
      transform-gpu
      rounded-lg
      bg-white
      px-4
      py-2
      drop-shadow-01
      transition
      hover:drop-shadow-02
      md:w-auto
    "
  >
    <GAItem :id="props.productCode ?? ''" :item="[props]">
      <GASelectItem>
        <div class="flex h-full w-full flex-col justify-between gap-2">
          <!-- Top (image + title + reviews) -->
          <div>
            <div
              class="
                pointer-events-none
                absolute
                left-4
                top-2
                z-20
                flex flex-wrap
                gap-2
              "
            >
              <BadgeProduct v-for="promo in promotions" v-bind="promo" />
            </div>
            <div class="product-image-container mb-2">
              <AppLink
                :isDisabled="disableLink"
                :to="path"
                @click="clickProductEvent"
              >
                <UtilsWrapperImage
                  v-if="productImage"
                  v-bind="productImage"
                  sizes="sm:242px md:18vw lg:18vw xl:18vw"
                  class="wrapper-image-fixed-164 wrapper-image-fit-contain"
                />

                <UtilsWrapperImage
                  v-else
                  v-bind="fallbackImage"
                  class="wrapper-image-fixed-164 wrapper-image-fit-contain"
                />
              </AppLink>
            </div>
            <div
              class="
                title
                beaver-bold
                flex
                h-fit
                cursor-pointer
                items-start
                justify-self-start
                px-1
                text-left
              "
            >
              <AppLink
                :isDisabled="disableLink"
                :to="path"
                @click="clickProductEvent"
              >
                {{ title }}
              </AppLink>
            </div>
          </div>
          <!-- Bottom -->
          <div class="h-max">
            <div class="mb-2 px-1">
              <div
                v-if="rating > 0 && reviews > 0"
                class="rating-container flex items-center gap-1"
              >
                <UtilsStars :stars="rating" />
                <span class="mouse">
                  {{ reviews }}
                </span>
              </div>
              <div v-if="inStock" class="price-container mt-2">
                <div class="flex items-center">
                  <span
                    v-if="currentPrice !== undefined"
                    class="discount-price elephant-bold text-black-800"
                  >
                    {{ formatMoney(currentPrice) }}
                  </span>
                  <span
                    v-if="isGreaterThan(oldPrice, currentPrice)"
                    class="full-price beaver ml-2 text-black-80 line-through"
                  >
                    {{ formatMoney(oldPrice) }}
                  </span>
                </div>
                <template
                  v-if="
                    productUtils.isDisplayablePricePerUnit(
                      pricePerUnit,
                      unitType
                    )
                  "
                >
                  <span class="unit-per-price mouse text-black-80">
                    {{
                      formattedPricePerUnit(formatMoney(pricePerUnit), unitType)
                    }}
                  </span>
                </template>
              </div>
              <div v-else class="available-container">
                <span class="mouse text-negative-main">{{
                  availableIn
                    ? `${$t(
                        "productTile.overall.availableIn"
                      )} ${availableIn} ${$t(
                        availableIn > 1
                          ? "productTile.overall.days"
                          : "productTile.overall.day"
                      )}`
                    : $t("productTile.overall.notAvailable")
                }}</span>
              </div>
            </div>
            <div
              class="button-container"
              v-if="
                !props.disableCallToActionOnCard &&
                currentPrice &&
                currentPrice > 0
              "
            >
              <UtilsProductCardButtons
                v-bind="productCardButton"
                @on-add-to-cart="handleAddToCart($event)"
                @on-notify-me="handleNotifyMe"
                @click="preventNavigation"
              />
            </div>
          </div>
        </div>
      </GASelectItem>
    </GAItem>
  </div>
</template>

<style lang="scss" scoped>

.chips-container {
  padding: inherit;
}

</style>
